import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/FrontPage.vue'),
        },
        {
            path: '/signup',
            name: 'signup',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "signup" */ '../views/SignupActivity.vue'),
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/LoginActivity.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/InteractionCenter.vue'),
        },
        {
            path: '/portal',
            name: 'portal-main',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "portal-main" */ '../views/PortalMainActivity.vue'),
        },
        {
            path: '/portal/vault',
            name: 'portal-vault',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "portal-vault" */ '../views/PortalVaultActivity.vue'),
        },
        {
            path: '/portal/ultrasign',
            name: 'portal-ultrasign',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "portal-ultrasign" */ '../views/PortalSignatureActivity.vue'),
        },
        {
            path: '/portal/ultrasign/document',
            name: 'portal-ultrasign-document',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "portal-ultrasign-document" */ '../views/PortalSignatureDocumentActivity.vue'),
        },
        {
            path: '/dashboard',
            name: 'user-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/UserDashboard.vue'),
        },
        {
            path: '/profile',
            name: 'profile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileDashboard.vue'),
        },
        {
            path: '/profile/setup',
            name: 'profile-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-setup" */ '../views/profile/ProfileSetup.vue'),
        },
        {
            path: '/profile/authn/loginfront',
            name: 'profile-settings-authn-loginfront',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn-loginfront" */ '../views/profile/authn/LoginFront.vue'),
        },
        {
            path: '/profile/authn',
            name: 'profile-settings-authn',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn" */ '../views/profile/AuthnSettings.vue'),
        },
        {
            path: '/profile/authz',
            name: 'profile-settings-authz',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authz" */ '../views/profile/AuthzSettings.vue'),
        },
        {
            path: '/profile/session',
            name: 'profile-settings-session',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-session" */ '../views/profile/SessionSettings.vue'),
        },
        {
            path: '/profile/delete',
            name: 'profile-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-delete" */ '../views/profile/DeleteProfile.vue'),
        },
        {
            path: '/service',
            name: 'service-admin',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin" */ '../views/service/AdminDashboard.vue'),
        },
        {
            path: '/service/setup',
            name: 'service-admin-setup',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-setup" */ '../views/service/SystemSetup.vue'),
        },
        {
            path: '/service/settings',
            name: 'service-admin-settings',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-settings" */ '../views/service/SystemSettings.vue'),
        },
        // {
        //     path: '/service/c2',
        //     name: 'service-admin-c2',
        //     meta: { layout: 'admin-layout' },
        //     component: () => import(/* webpackChunkName: "service-admin-c2" */ '../views/service/AdminC2.vue'),
        // },
        {
            path: '/service/dns',
            name: 'service-admin-dns',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-dns" */ '../views/service/AdminDns.vue'),
        },
        {
            path: '/service/dynamic-shared-domain',
            name: 'service-admin-dynamic-shared-domain',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-dynamic-shared-domain" */ '../views/service/DynamicSharedDomain.vue'),
        },
        {
            path: '/system/edit/user',
            name: 'system-edit-user',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "system-edit-user" */ '../views/service/EditUser.vue'),
        },
        {
            path: '/user/account-list',
            name: 'user-account-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-account-list" */ '../views/user/AccountList.vue'),
        },
        {
            path: '/user/create/account',
            name: 'user-create-account',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-create-account" */ '../views/user/CreateAccount.vue'),
        },
        {
            path: '/search',
            name: 'search',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "search" */ '../views/SearchActivity.vue'),
        },
        {
            path: '/account/:accountId',
            name: 'account-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-dashboard" */ '../views/account/AccountDashboard.vue'),
        },
        {
            path: '/account/:accountId/settings',
            name: 'account-settings',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-settings" */ '../views/account/AccountSettings.vue'),
        },
        {
            path: '/account/:accountId/setup',
            name: 'account-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-setup" */ '../views/account/AccountSetup.vue'),
        },
        {
            path: '/account/:accountId/delete',
            name: 'account-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete" */ '../views/account/DeleteAccount.vue'),
        },
        {
            path: '/account/:accountId/create/client-token',
            name: 'account-create-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-client-token" */ '../views/account/CreateClientToken.vue'),
        },
        {
            path: '/account/:accountId/create/contact',
            name: 'account-create-contact',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-contact" */ '../views/account/CreateContact.vue'),
        },
        {
            path: '/account/:accountId/create/file',
            name: 'account-create-file',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-file" */ '../views/account/CreateFile.vue'),
        },
        {
            path: '/account/:accountId/create/link-contact-file',
            name: 'account-create-link-contact-file',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-link-contact-file" */ '../views/account/CreateLinkContactFile.vue'),
        },
        {
            path: '/account/:accountId/create/signature-document',
            name: 'account-create-signature-document',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-signature-document" */ '../views/account/CreateSignatureDocument.vue'),
        },
        {
            path: '/account/:accountId/create/watermark',
            name: 'account-create-watermark',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-watermark" */ '../views/account/CreateWatermark.vue'),
        },
        {
            path: '/account/:accountId/contact/:contactId',
            name: 'account-edit-contact',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-contact" */ '../views/account/EditContact.vue'),
        },
        {
            path: '/account/:accountId/file/:fileId',
            name: 'account-edit-file',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-file" */ '../views/account/EditFile.vue'),
        },
        {
            path: '/account/:accountId/file/:fileId/watermark',
            name: 'account-edit-file-watermark',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-file-watermark" */ '../views/account/EditFileWatermark.vue'),
        },
        {
            path: '/account/:accountId/watermark/:watermarkId',
            name: 'account-edit-watermark',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-watermark" */ '../views/account/EditWatermark.vue'),
        },
        {
            path: '/account/:accountId/signature-document/:signatureDocumentId/edit',
            name: 'account-edit-signature-document',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-signature-document" */ '../views/account/EditSignatureDocument.vue'),
        },
        {
            path: '/account/:accountId/search/client-token',
            name: 'account-search-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-client-token" */ '../views/account/SearchClientToken.vue'),
        },
        {
            path: '/account/:accountId/search/contact',
            name: 'account-search-contact',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-contact" */ '../views/account/SearchContact.vue'),
        },
        {
            path: '/account/:accountId/search/file',
            name: 'account-search-file',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-file" */ '../views/account/SearchFile.vue'),
        },
        {
            path: '/account/:accountId/search/user',
            name: 'account-search-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-user" */ '../views/account/SearchUser.vue'),
        },
        {
            path: '/account/:accountId/search/signature-document',
            name: 'account-search-signature-document',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-signature-document" */ '../views/account/SearchSignatureDocument.vue'),
        },
        {
            path: '/account/:accountId/search/watermark',
            name: 'account-search-watermark',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-watermark" */ '../views/account/SearchWatermark.vue'),
        },
        {
            path: '/account/:accountId/leak-detection',
            name: 'account-view-leak-detection',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-view-leak-detection" */ '../views/account/ViewLeakDetection.vue'),
        },
        {
            path: '/account/:accountId/signature-document/:signatureDocumentId',
            name: 'account-view-signature-document',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-view-signature-document" */ '../views/account/ViewSignatureDocument.vue'),
        },
        {
            path: '/account/:accountId/user/:userId',
            name: 'account-view-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-view-user" */ '../views/account/ViewUser.vue'),
        },
        {
            path: '/account/:accountId/signature-document/:signatureDocumentId/delete',
            name: 'account-delete-signature-document',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete-signature-document" */ '../views/account/DeleteSignatureDocument.vue'),
        },
        {
            path: '/webauthz/grant',
            name: 'webauthz-grant',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-grant" */ '../views/webauthz/WebauthzGrant.vue'),
        },
        {
            path: '/webauthz/prompt',
            name: 'webauthz-prompt',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-prompt" */ '../views/webauthz/WebauthzPrompt.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
